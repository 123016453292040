import { graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Footer from "../components/footer"
import NavBar from "../components/index/navbar"
import Layout from "../components/layout"
import Seo from "../components/SEO/seo"
import { Sidebar } from "../components/ui/sidebar"
import { collapseEvent } from "../helpers/collapseFuntion"
import { ListPriceButton } from "../helpers/ListPriceButton"
import "../styles/main.scss"

const Hormonas = () => {
  const { handleEventCollapse } = collapseEvent()

  //PAGE IMAGE
  const imageUrl = useStaticQuery(
    graphql`
      query {
        siteImg: file(relativePath: { eq: "Services/hormonas_52944281.jpeg" }) {
          childImageSharp {
            fluid {
              src
            }
          }
        }
      }
    `
  )

  const { src: imgSource } = imageUrl.siteImg.childImageSharp.fluid //image

  const parametersSEO = {
    description:
      "Los marcadores tumorales son sustancias que se encuentran en las células cancerosas o normales del cuerpo o que se producen en respuesta al cáncer o algunas afecciones benignas (no cancerosas).",
    lang: "es-SV",
    meta: [],
    title: "Hormonas y Marcadores Laboratorioclinico",
    imgSrc: imgSource,
    currentURL: "https://laboratorioclinico.sv/hormonas_y_marcadores",
    canonicalURL: "https://laboratorioclinico.sv",
    ogTitle: "Hormonas y Marcadores Laboratorioclinico",
    keywords: [
      "que son las hormonas",
      "laboratorio de analisis clinico",
      "examenes de laboratorio",
    ],
  }

  return (
    <Layout>
      <Seo {...parametersSEO} />
      <div className="banner-services__container">
        <svg
          width="1418"
          height="509"
          viewBox="0 0 1418 509"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1170.03 297.846L1121.05 337.513L1029.23 198.661C1024.68 191.802 1014.82 187.555 1003.41 187.555C994.136 187.555 982.379 190.604 977.179 199.205L897.062 332.178L786.458 14.2989C782.795 3.73804 769.442 0 759.162 0C747.168 0 733.874 4.7542 731.215 15.3877L634.969 399.39L537.008 146.509C533.05 136.275 519.815 132.646 509.653 132.646C499.786 132.646 487.556 135.985 482.888 145.312L397.04 317.806L333.821 225.988C329.212 219.274 319.345 215.137 308.061 215.137C298.962 215.137 290.69 217.895 285.313 222.722L211.046 288.229L205.905 290.333H29.5417C13.2347 290.333 0 298.463 0 308.479C0 318.496 13.2347 326.625 29.5417 326.625H223.571C232.552 326.625 241.06 323.939 246.377 319.149L302.388 270.046L375.652 376.345C380.319 383.131 389.891 387.196 401.235 387.196C413.701 387.196 424.277 382.188 428.118 374.494L506.344 217.278L613.521 494.147C617.539 504.418 630.183 508.083 640.7 508.083C652.694 508.083 665.987 503.329 668.646 492.66L763.475 114.319L862.321 398.483C865.748 408.354 878.687 412.818 889.913 412.818C901.848 412.818 911.538 408.463 915.969 401.168L1004.59 254.223L1088.85 381.607C1093.4 388.466 1103.32 392.712 1114.73 392.712C1123.71 392.712 1131.98 389.99 1137.3 385.236L1205 327.786L1210.14 326.117H1388.93C1405 326.117 1418 318.133 1418 308.262C1418 298.39 1405 290.333 1388.93 290.333H1192.66C1183.73 290.333 1175.29 293.128 1170.03 297.846V297.846Z"
            fill="white"
          />
        </svg>
        <div className="banner-services__container-fade-in"></div>
        <div className="banner-services__container-banner">
          <NavBar />
          <header className="banner-service">
            <div className="banner-service__button">
              <i className="fas fa-circle fa-xs"></i>
              <p>Servicios de Alta Calidad</p>
            </div>
            <h1 className="banner-service__title mt-2 mb-3">
              Hormonas y Marcadores
            </h1>
          </header>
        </div>
      </div>
      <div className="content-services__container">
        <StaticImage
          src="../images/content.png"
          className="banner-content__background"
          placeholder="tracedSVG"
          width={1600}
          alt="background"
        ></StaticImage>

        {/* MAIN CONTENT WITH SIDEBAR */}
        <section className="content-services">
          <Sidebar />
          <div className="content-services__container">
            <div className="content-services__container-images">
              <StaticImage
                src="../images/Services/hormonas_52944281.jpeg"
                alt="hormonas_img"
                placeholder="none"
                layout="constrained"
              />
            </div>
            <div className="content-services__container-section1 mt-2">
              <h2 className="mb-1">Hormonas y Marcadores</h2>
              <p>
                Las hormonas son mensajeros químicos producidos en glándulas
                como la tiroides, el timo y las suprarrenales, así como en
                células y tejidos como el corazón y el estómago. Son
                responsables de la digestión, el metabolismo y el crecimiento,
                pero también tienen un papel en nuestra vida sexual y mucho más.
                Las hormonas llegan a las partes del cuerpo en las que son
                necesarias a través de la sangre. Esto provoca reacciones en
                cadena, como el hambre o la sed, que, una vez satisfechas,
                devuelven al cuerpo a su equilibrio adecuado.
              </p>
            </div>
            <div className="content-services__container-section2 mt-2">
              <ul>
                <li>
                  <i className="fas fa-check fa-lg mr-1"></i>
                  Hormona Luteinizante
                </li>
                <li>
                  <i className="fas fa-check fa-lg mr-1"></i>
                  Prolactina
                </li>
                <li>
                  <i className="fas fa-check fa-lg mr-1"></i>
                  Hormona de crecimiento
                </li>
                <li>
                  <i className="fas fa-check fa-lg mr-1"></i>
                  Testosterona
                </li>
                <li>
                  <i className="fas fa-check fa-lg mr-1"></i>
                  T3
                </li>
              </ul>
              <ul>
                <li>
                  <i className="fas fa-check fa-lg mr-1"></i>
                  T4
                </li>
                <li>
                  <i className="fas fa-check fa-lg mr-1"></i>
                  CA 125
                </li>
                <li>
                  <i className="fas fa-check fa-lg mr-1"></i>
                  Anticuerpos Toxoplasma
                </li>
                <li>
                  <i className="fas fa-check fa-lg mr-1"></i>
                  CA 19.9
                </li>
                <li>
                  <i className="fas fa-check fa-lg mr-1"></i>
                  CA 15.3
                </li>
              </ul>
            </div>
            <div className="content-services__container-section3">
              <h2 className="mb-1 mt-2">Hormonas como marcadores tumorales.</h2>
              <p>
                Dado que las hormonas producidas por los tumores malignos suelen
                poder ser identificadas y cuantificadas mediante inmunoensayos
                diseñados para medir las hormonas producidas normalmente, la
                elevación de una determinada hormona no se considera diagnóstica
                de un tumor específico.
              </p>
            </div>
            <ul className="content-services__container-section4 mt-2 mb-3">
              <li>
                <div
                  className="collapse-container"
                  onClick={handleEventCollapse}
                >
                  <p className="collapse-title">¿Para qué hacerme un CEA?</p>
                  <i className="fa-solid fa-caret-right collapse-icon"></i>
                </div>
              </li>
              <li className="collapse-text">
                <p>
                  Los médicos no siempre ven signos evidentes de crecimiento del
                  cáncer tras el diagnóstico. Tienen que buscar pistas. Una
                  forma de hacerlo es con la prueba del antígeno
                  carcinoembrionario. Mide una proteína llamada CEA en la
                  sangre.
                </p>
                <br />
                <p>
                  Las personas con algunos tipos de cáncer tienen niveles de
                  esta sustancia más altos de lo normal. Esta prueba ayuda a su
                  médico a averiguar si el suyo ha crecido y si su tratamiento
                  ha funcionado.
                </p>
              </li>
              <li>
                <div
                  className="collapse-container"
                  onClick={handleEventCollapse}
                >
                  <p className="collapse-title">¿Para que sirve el Dimero D?</p>
                  <i className="fa-solid fa-caret-right collapse-icon"></i>
                </div>
              </li>
              <li className="collapse-text">
                <p>
                  Este examen es últil para detectar o descartar episodios de
                  coagulación (trombosis) y para ayudar a diagnosticar
                  condiciones relacionadas con la trombosis.
                </p>
              </li>
              <li>
                <div
                  className="collapse-container"
                  onClick={handleEventCollapse}
                >
                  <p className="collapse-title">
                    ¿Por qué dejan el examen de la Tiroides?
                  </p>
                  <i class="fa-solid fa-caret-right collapse-icon"></i>
                </div>
              </li>
              <li className="collapse-text">
                <p>
                  La prueba, denominada prueba de la función tiroidea, examina
                  los niveles de la hormona estimulante del tiroides (TSH) y la
                  tiroxina (T4) en la sangre. Los médicos pueden referirse a
                  esto como T4 "libre" (FT4). Un nivel alto de TSH y un nivel
                  bajo de T4 en la sangre podría significar que tiene una
                  tiroides poco activa. Si los resultados de sus pruebas
                  muestran un nivel elevado de TSH pero un nivel normal de T4,
                  puede correr el riesgo de desarrollar una tiroides hipoactiva
                  en el futuro.
                </p>
              </li>
            </ul>
          </div>
        </section>
      </div>
      <ListPriceButton />
      <Footer />
    </Layout>
  )
}
export default Hormonas
